import styled from 'styled-components'

export const StyledNav = styled.nav`
  margin-bottom: 48px;

  ul,
  li {
    list-style: none;
  }
`

export const StyledSections = styled.ul`
  padding: 0;
  margin: 0;
`
