import React from 'react'
import PropTypes from 'prop-types'

import Section from './section/section'
import { StyledNav, StyledSections } from './vertical-menu.styles'

const VerticalMenu = ({ sections, currentSectionId }) => (
  <StyledNav>
    <StyledSections>
      {sections.map(section => (
        <Section
          key={section.id}
          section={section}
          isActive={currentSectionId === section.id}
        />
      ))}
    </StyledSections>
  </StyledNav>
)

VerticalMenu.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  currentSectionId: PropTypes.number,
}

VerticalMenu.defaultProps = {
  currentSectionId: null,
}

export default VerticalMenu
